// User config
import { ColumnState } from 'ag-grid-enterprise';

import { FlightFieldName } from '@/models/enums/flight/flight-field-name';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { FilterFieldField, RouteFilterType } from '@/modules/api/shared-contracts';
import { CrossFilterType } from '@/modules/control/models/crossfilter/crossfilter-configuration';
import { CrossFilterPath, CrossFilterType as DynamicCrossFilterType } from '@/modules/cross-filters/types/cross-filter.types';
import { GridState } from '@/modules/grid/store/grid.store';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { ExportSeparator } from '@/modules/user-settings/types';

export type DatePattern = 'YYYY/MM/DD' | 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'DD.MM.YYYY' | 'MM/DD/YYYY';

export type Language =
  | 'en-US'
  | 'zh-CN'
  | 'ru-RU'
  | 'fr-FR'
  | 'es-ES'
  | 'en-GB'
  | 'de-DE'
  | 'pt-BR'
  | 'en-CA'
  | 'es-MX'
  | 'it-IT'
  | 'ja-JP';

export enum LafLoadFactorColoring {
  OFF = 'OFF',
  RED_TO_GREEN = 'RED_TO_GREEN',
}

export interface UserConfigModel {
  fontSize: number;
  margin: number;
  datePattern: DatePattern | null;
  language: Language;
  numberPattern: string;
  inventoryGridColumnState: ColumnState[];
  departedGridColumnState: ColumnState[];
  /**
   * The column state of the grid when {@link ControlDataDisplayMode} is set to TABLE.
   * I know, the name is confusing, but we are not going to change it due to required migrations :-).
   * Technically we should call it `controlDataDisplayModeTableColumnState`.
   */
  controlGridColumnState: ColumnState[];
  /**
   * The column state of the grid when {@link ControlDataDisplayMode} is set to GRID.
   */
  controlDataDisplayModeGridColumnState: Partial<GridState>;
  bookingDetailsGridColumnState: Partial<GridState>;
  flightLegCabinGridColumnState: Partial<GridState>;
  tourOperatorGridColumnState: Partial<GridState>;
  cabinInventoryGridColumnState: ColumnState[];
  detailsViewTableOrder: boolean;
  departedViewTableOrder: boolean;
  authorities: Authority[];
  inventoryManagementMethodology: InventoryManagementMethodology;
  controlSettings: ControlSettings;
  controlBookingsPickUpPoints: number[];
  controlPerformanceBandPickUpPoints: number[];
  controlByosPickUpPoints: number[];
  detailsBookingsPickUpPoints: number[];
  detailsPerformanceBandPickUpPoints: number[];
  detailsByosPickUpPoints: number[];
  lafColoring: LafLoadFactorColoring;
  displaySeatAvailabilityAsZero: boolean;
  exportSeparator: ExportSeparator;
  optimizationProfile: string[];
  optimizationTactic: string[];
  viewedReleaseVersions: string[];
  controlDisplayTourOperatorSetting?: AssignableTourOperatorContract[];
}

export type UserConfigModelDTO = Partial<UserConfigModel>;

export type GridColumnStateKeys = keyof GridColumnStates;

export type GridColumnStates = Partial<
  Pick<
    UserConfigModel,
    | 'controlGridColumnState'
    | 'cabinInventoryGridColumnState'
    | 'inventoryGridColumnState'
    | 'bookingDetailsGridColumnState'
    | 'flightLegCabinGridColumnState'
    | 'tourOperatorGridColumnState'
  >
>;

export interface ControlSettings {
  filters?: FilterFieldField[];
  /**
   * This is the actual state of the crossfilter so the UI can restore it on a new session.
   * List of unique crossfilter id's.
   */
  crossfilterState?: CrossfilterUserConfig[];
  competitors?: string[];
  groupKey?: FlightFieldName[];
  routeFilterType?: RouteFilterType;
  dynamicCrossFilters?: DynamicCrossFilterControlSetting[];
  controlDataDisplayMode?: ControlDataDisplayMode;
}

export type DynamicCrossFilterControlSetting = {
  type: DynamicCrossFilterType;
  path: CrossFilterPath;
};

export const CONTROL_DATA_DISPLAY_MODE = {
  TABLE: 'TABLE',
  GRID: 'GRID',
} as const;

export type ControlDataDisplayMode = keyof typeof CONTROL_DATA_DISPLAY_MODE;

export type CrossfilterUserConfig = {
  widget: CrossFilterType;
  cabinCode?: string;
};

export interface IUserService {
  getByEmail(email: string): Promise<UserModel>;
  patchConfig(id: number, config: UserConfigModelDTO): Promise<UserConfigModelDTO>;
  changePassword(currentPassword: string, newPassword: string): Promise<void>;
}
