import type { AxiosProgressEvent } from 'axios';

import { ApiClient } from '@/modules/api/base-client';
import { ImportSettings, OptimizationProfileLevelDataImportAssignment } from '@/modules/data-import/api/data-import/data-import-contracts';

import { IOptimizationProfileLevelConfiguration, IOptimizationProfiles, ProfileLevelParams } from './optimisation-profiles.contracts';

export class OptimizationProfileService {
  private basePath = 'optimisation-profile';

  public async getAll(): Promise<IOptimizationProfiles> {
    return await ApiClient.get<IOptimizationProfiles>(`${this.basePath}`);
  }

  public async getForFlight(flightKey: string | undefined): Promise<IOptimizationProfiles> {
    return await ApiClient.get<IOptimizationProfiles>(`${this.basePath}`, { flightKey });
  }

  public async bulkAssign(request: OptimizationProfileLevelDataImportAssignment): Promise<void> {
    return await ApiClient.patch<void>(`${this.basePath}/level/bulk-assign`, request);
  }

  public async getOptimizationProfileLevelDetails(params: ProfileLevelParams): Promise<IOptimizationProfileLevelConfiguration> {
    return await ApiClient.get<IOptimizationProfileLevelConfiguration>(
      `${this.basePath}/level/${params.cabinCode}/${params.profileGroupId}/${params.levelId}`,
    );
  }

  public async upload(
    files: File[],
    progressCallback: (progressEvent: AxiosProgressEvent) => void,
    importSettings: ImportSettings = {},
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append('delimiter', ',');
    files.forEach((file) => {
      formData.append('files', file);
    });
    Object.keys(importSettings).map((importSetting: string) => {
      formData.append(importSetting, importSettings[importSetting as keyof ImportSettings].toString());
    });
    await ApiClient.uploadFiles(`/${this.basePath}/management/upload`, formData, progressCallback);
    return true;
  }
}

export const optimizationProfileService = new OptimizationProfileService();
